<template>
	<el-button type="primary" @click="call">注册</el-button>
</template>

<script>
	import JsSIP from 'jssip';
	export default {
		name: 'sip',
		data() {
			return {
				msg_log: {},
				currentSession: "",
				userAgent: "",
				peer: "",
				callPhone: '17600131812'
			}
		},
		methods: {
			sip_init() {
				this.msg_log = {
					el: document.querySelector(".message"),
					log(msg) {
						console.log("loglog", msg);
						this.titleText = msg;
						this.el.innerHTML +=
							`<span class="success">${new Date().toLocaleTimeString()}:${msg}</span>`;
					},
					error(msg) {
						console.log("error", msg);
						this.titleText = msg;
						this.el.innerHTML +=
							`<span class="error">${new Date().toLocaleTimeString()}:${msg}</span>`;
					}
				}
				const selfVideo = document.querySelector("#selfVideo");
				// 本地加载完成 对端加载完成
				const socket = new JsSIP.WebSocketInterface("ws://47.94.104.253:5060");
				console.log(socket, 'socket')
				const configuration = {
					sockets: [socket],
					// uri: `sip:13041143614@47.94.104.253:5060;transport=wss`, // loginPhone为11位以1开头的数字
					uri: 'sip:13041143614@47.94.104.253', // loginPhone为11位以1开头的数字
					password: "j82OZvhf", // 密码
					register: true, // 自动注册
					session_timers: false,
				};
				var ua = new JsSIP.UA(configuration);
				console.log(ua, 'uauauauaua')
				ua.on("connected", (data) => {
					console.log(data, "连线中connected");
				});
				ua.on("connecting", (data) => {
					console.log(data, "接线中connecting")
				});
				ua.on("disconnected", (data) => {
					console.error(data, "取消连线disconnected")
				});
				ua.on("registered", (data) => {
					console.log(`--${name === "offer" ? 2001 : 2002}注册成功`);
				});
				ua.on("registrationExpiring", (data) => {
					// console.log("注册即将到期,重新注册")
					console.log(data, "注册即将到期,重新注册registrationExpiring")
				});
				ua.on("registrationFailed", (data) => {
					console.error(data, "注册失败registrationFailed")
				});
				ua.on("unregistered", (data) => {
					console.log(data, "取消注册unregistered")
				});
				ua.on("sipEvent", (data) => {
					// console.log("sipEvent")
					console.log(data, "sipEvent")
				});
				ua.on("newRTCSession", function(data) {
					const { session, request, originator } = data;
					if (originator === "remote") {
						console.log(data, "对方打电话过来了~~~");
					} else {
						console.log(data, "拨打电话中~~~");
					}
					currentSession = session;
					// 连接中
					session.on("connecting", (data) => {
						console.log(data, "通话连线时候触发")
					});
					// 连接已接受
					session.on("accepted", (data) => {
						this.videoSpinner = true;
						console.log(data, "通话接受时候触发")
					});
					session.on("sdp", () => {
						console.log(data, "交换sdp信令事件触发")
					});
					session.on("failed", (data) => {
						// window.open("https://192.168.30.236:16376","验证")
						console.log(data, "通话失败事件触发")
					});
					session.on("reinvite", (data) => {
						console.log(data, "重新协商事件触发");
						audioElement.srcObject = null;
					});
					session.on("progress", (data) => {
						if (originator === "remote") {
							console.log(data, "电话过来拉~~~~~~~~~··");
							session.answer({
								mediaConstraints: {
									audio: true,
									video: this.callType ===
										'video' ? true : false
								},
								// mediaStream: localStream,
							});
							console.log(data, "我接听了");
						}
						console.log(data, "接听事件在progress中触发");
					});
					session.on("confirmed", () => {
						console.log("呼叫确认--设置媒体流到音视频中");

						// 播放视频
						if (this.callType === "video") {
							const remoteVideo = document.querySelector("#remoteVideo");
							console.log(remoteVideo, "remoteVideoremoteVideo");
							selfVideo.srcObject = null;
							remoteVideo.srcObject = null;
							// 接入的视频流
							if (session?._connection.getRemoteStreams().length > 0) {
								remoteVideo.srcObject = session?._connection.getRemoteStreams()[0];
								remoteVideo.play();
							}
						} else if (this.callType === "audio") {
							// 语音播放
							const stream = new MediaStream();
							const receivers = this.currentSession.connection?.getReceivers();
							if (receivers)
								receivers.forEach((receiver) => stream.addTrack(receiver.track));
							audioElement.srcObject = stream;
							// 最后都要播放
							audioElement.oncanplay = () => {
								audioElement.play();
							};

						}
					});
					session.on("peerconnection", (data) => {
						console.log(data, "对等连接事件触发");
					});
					session.on("connecting", (data) => {
						this.peer = session._connection;
						console.log(data, "对等连接建立，connecting");
					});
					session.on("ended", (data) => {
						console.log(data, "通话结束")
					});
				});
				// ua.start();
				this.userAgent = ua;

			},
			call() {
				console.log(this.userAgent, '是是是')
				this.userAgent.start();
				const eventHandlers = {
					progress: function(e) {
						console.log("call is in progress");
					},
					failed: function(e) {
						this.titleText = "对方已挂断！";
						this.$message.error("无人应答！")
						this.VoiceVisible = false;
						this.ViewsVisible = false;
						console.log("call failed: ", e);
					},
					ended: function(e) {
						this.$message.error("通话结束")
						console.log("call ended : ", e);
					},
					confirmed: function(e) {
						console.log("call confirmed");
					},
				}
				const opt = {
					mediaConstraints: {
						audio: true,
						video: this.callType === 'video' ? true : false
					},
					eventHandlers,
				};
				// callPhone 要拨打的电话号码
				this.userAgent.call(`sip:${this.callPhone}@47.94.104.253:5060`, opt);

			},
		},
		mounted() {
			this.sip_init();
		}
	}
</script>
<style>
	#agentNoInput,
	#phoneNumInput {
		border-radius: 16px;
	}

	#menuBtn.ant-btn-link {
		color: #000;
		transition: .3s
	}

	#menuBtn.ant-btn-link:hover {
		color: rgba(255, 255, 255, 0.8)
	}

	/*#menuBtn.ant-btn-link:hover{color:#40a9ff}*/
	/* 	.agentBar>>>.ant-input {
		height: 50px;
	} */
</style>